export interface IModifiedTemplatesResponse {
  templates: IMessageTemplateWithRights[];
}

export interface ITemplateRights {
  can_edit: boolean;
  can_add_favorite: boolean;
  can_remove_favorite: boolean;
}

export type IMessageTemplateWithRights = IMessageTemplate & ITemplateRights;
export type ICMSMessageTemplateWithRights = ICMSMessageTemplate &
  ITemplateRights;

export type AnyTemplate = Partial<IMessageTemplateWithRights> &
  Partial<ICMSMessageTemplateWithRights>;

export const favorites: ITemplateCategory = {
  displaySlug: "favoritter",
  title: "Favoritter",
};

export const ownTemplates: ITemplateCategory = {
  displaySlug: "egne-maler",
  title: "Egne maler",
};
