import { Route as RouterRoute, Outlet, RouteProps } from "react-router-dom";

import { Suspense } from "./Suspense";

function Route({ element, ...props }: RouteProps) {
  return (
    <RouterRoute
      element={<Suspense>{element || <Outlet />}</Suspense>}
      {...props}
    />
  );
}

export { Route };
