import { lazy } from "react";
import { Routes, Route } from "react-router-dom";

import { usePortalQuery } from "../../components/usePortalQuery";

const Overview = lazy(() => import("./Overview"));
const Entry = lazy(() => import("./Entry"));

function Photos() {
  const { data } = usePortalQuery<
    IApproveImagesResponse,
    unknown,
    IPreviewImage[]
  >("photos", {
    select: (data) => data.images,
    suspense: true,
  });
  const images = data as IPreviewImage[];

  return (
    <Routes>
      <Route path="" element={<Overview images={images} />} />
      <Route path=":id" element={<Entry images={images} />} />
    </Routes>
  );
}

export default Photos;
