import { useParams } from "react-router-dom";
import { usePortalQuery } from "../../../../../components/usePortalQuery";
import { useProfile } from "../../../../../components/useProfile";
import { favorites, IMessageTemplateWithRights, ownTemplates } from "./types";
import { useCMSTemplates } from "./useCMSTemplates";
import { useTemplateMutations } from "./useTemplateMutations";

export const defaultCategories: ITemplateCategory[] = [favorites, ownTemplates];

export const useTemplates = () => {
  const params = useParams();
  const { data: user } = useProfile(undefined, { staleTime: 60 * 1000 });

  const cmsTemplatesQueries = useCMSTemplates();

  const favoritesQuery = usePortalQuery<IMessageTemplatesResponse>(
    "agents/message_templates/favorites",
    {
      staleTime: 60 * 1000,
      enabled: user?.type === "agent",
    }
  );

  const personalQuery = usePortalQuery<IMessageTemplatesResponse>(
    "agents/message_templates/personal",
    {
      staleTime: 60 * 1000,
      enabled: user?.type === "agent",
    }
  );

  const isFavorites = params.categoryId === favorites.displaySlug;
  const isOwnTemplates = params.categoryId === ownTemplates.displaySlug;

  const userTemplates: IMessageTemplateWithRights[] = (
    (isFavorites
      ? favoritesQuery.data?.templates
      : isOwnTemplates
      ? personalQuery.data?.templates
      : []) || []
  ).map((template) => {
    const isFavorite = !!favoritesQuery.data?.templates.some(
      (favTemp) => favTemp.id === template.id
    );

    return {
      ...template,
      can_remove_favorite: isFavorite,
      can_add_favorite: !isFavorite,
      can_edit:
        personalQuery.data?.templates.some(
          (ownTemplate) => ownTemplate.id === template.id
        ) || false,
    };
  });

  const templateToEdit =
    userTemplates.find(
      (template) => template.id?.toString() === params.templateId
    ) ||
    ({
      content: "",
      title: "",
    } as IMessageTemplate);

  const mutations = useTemplateMutations({
    favoritesQuery,
    cmsFavoritesQuery: cmsTemplatesQueries.cmsFavoritesQuery,
    cmsTemplatesQuery: cmsTemplatesQueries.cmsTemplatesQuery,
    personalQuery,
    userTemplates,
  });

  return {
    isLoading: favoritesQuery.isLoading || personalQuery.isLoading,
    templateToEdit,
    favoriteTemplates: [
      ...(favoritesQuery.data?.templates || []),
      ...cmsTemplatesQueries.cmsFavorites,
    ] as Array<ICMSMessageTemplate & IMessageTemplate>,
    userTemplates,
    cmsTemplates: cmsTemplatesQueries.cmsTemplates,
    categories: cmsTemplatesQueries.categories,
    ...mutations,
  };
};
