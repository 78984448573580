import { useEffect, useRef, useState } from "react";
import { useQueryClient, QueryKey, useMutation } from "react-query";
import { useParams } from "react-router-dom";
import redaxios, { Options } from "redaxios";

import { usePortalQuery } from "../../components/usePortalQuery";
import { useProfile } from "../../components/useProfile";
import { useWebSocket } from "../../components/useWebSocket";
import { contextPathMap, contextTitleMap } from "./utils";
import {
  ContextTypes,
  IConversation,
  IConversationResponse,
  IConversationsResponse,
  IMessage,
  IUser,
} from "../../api";

function useConversations() {
  const client = useQueryClient();
  const { lastJsonMessage } = useWebSocket({
    filter: (message) => ["online", "offline"].includes(message.action),
  });

  const conversations = usePortalQuery<
    IConversationsResponse,
    unknown,
    IConversation[]
  >("conversations", { select: ({ conversations }) => conversations });

  const queryKey = useRef(conversations.queryKey);
  useEffect(() => {
    if (lastJsonMessage) {
      const previousValue = client.getQueryData<IConversationsResponse>(
        queryKey.current
      );

      if (previousValue) {
        const conversations = previousValue.conversations.map((convo) => {
          return {
            ...convo,
            contact: updateUsersOnlineStatus(convo.contact, lastJsonMessage),
            users: updateUsersOnlineStatus(convo.users, lastJsonMessage),
          };
        });
        client.setQueryData(queryKey.current, { conversations });
      }
    }
  }, [lastJsonMessage, client]);

  return conversations;
}

function useConversation(
  context: string,
  sale_id: number,
  onSuccess?: (data: IConversationResponse) => void
) {
  const { data: user } = useProfile();
  const client = useQueryClient();
  const conversation = usePortalQuery<IConversationResponse, unknown>(
    `conversation/${context}`,
    {
      onSuccess,
    }
  );

  const { lastJsonMessage } = useWebSocket({
    filter: (message) => {
      return (
        message.action === "new_message" &&
        message.sale_id === sale_id &&
        message.data.context === context &&
        message.data.user_id !== user?.id
      );
    },
  });

  const queryKey = useRef(conversation.queryKey);
  useEffect(() => {
    if (lastJsonMessage !== null) {
      const previousValue = client.getQueryData<IConversationResponse>(
        queryKey.current
      ) || { messages: [] };

      const messages = [...previousValue.messages, lastJsonMessage.data];
      client.setQueryData<IConversationResponse>(queryKey.current, {
        messages,
        can_unread: lastJsonMessage.can_unread,
      });
    }
  }, [lastJsonMessage, queryKey, client]);

  return conversation;
}

function useConversationMutation(context: ContextTypes, queryKey: QueryKey) {
  const client = useQueryClient();
  const [url, { params }] = queryKey as [string, Options];

  return useMutation(
    `conversation/${context}`,
    (data: { content: string; user_id: number; id: number }) =>
      redaxios<{ message: IMessage }>(`/api/${url}`, {
        method: "post",
        data,
        params,
      }),
    {
      onSuccess: (data: { data: { message: IMessage } }) => {
        const previousValue = client.getQueryData<IConversationResponse>(
          queryKey
        ) || { messages: [] };

        client.setQueryData(queryKey, {
          ...previousValue,
          messages: [...previousValue.messages, { ...data.data.message }],
        });
      },
    }
  );
}

export const useMessageDraftMutation = (
  context: ContextTypes,
  queryKey: QueryKey
) => {
  const [url, { params }] = queryKey as [string, Options];
  return useMutation(
    `conversation/${context}/draft`,
    (data: { content: string }) =>
      redaxios(`/api/${url}/draft`, {
        method: "post",
        data,
        params,
      })
  );
};

export const useDeleteMessageDraftMutation = (
  context: ContextTypes,
  queryKey: QueryKey
) => {
  const [url, { params }] = queryKey as [string, Options];
  return useMutation(`conversation/${context}/draft`, () =>
    redaxios(`/api/${url}/draft`, {
      method: "delete",
      params,
    })
  );
};

function useMarkConversationAsUnread(queryKey: QueryKey) {
  const client = useQueryClient();

  const urlParams = useParams();
  const sale_id = parseInt(urlParams.sale_id || "0", 10);
  const qk = useRef(queryKey).current;

  return useMutation(
    (params: { conversation_id: number }) =>
      redaxios<{ unread: number }>(`/api/unread_conversation`, {
        method: "get",
        params: {
          ...params,
          sale_id,
        },
      }),
    {
      mutationKey: `unread-conversation/`,
      onSuccess: async (data, variables) => {
        await client.cancelQueries(qk);

        client.setQueryData<IConversationsResponse>(qk, (old) =>
          old
            ? {
                ...old,
                conversations: old.conversations.map((conversation) => {
                  if (conversation.id === variables.conversation_id) {
                    return {
                      ...conversation,
                      unread: data?.data?.unread || 1,
                      can_unread: false,
                    };
                  }

                  return conversation;
                }),
              }
            : { conversations: [] }
        );
      },
    }
  );
}

function useWritingStatus(
  context: string,
  currentUser: number,
  sale_id: number
) {
  const [writing, setWriting] = useState<number[]>([]);
  const { lastJsonMessage } = useWebSocket({
    filter: (message) => {
      const writingAction =
        message.action === "writing" &&
        message.data.context === context &&
        message.data.user_id !== currentUser;
      const messageAction =
        message.action === "new_message" &&
        message.data.context === context &&
        message.sale_id === sale_id;
      return writingAction || messageAction;
    },
  });

  useEffect(() => {
    const message = lastJsonMessage as
      | WebSocketMessageAction
      | WebSocketWritingAction;
    if (message !== null) {
      const user_id = message.data.user_id;

      if (message.action === "writing") {
        setWriting((o) => [...Array.from(new Set([...o, user_id]))]);
      }

      if (message.action === "new_message") {
        setWriting((o) => {
          const idx = o.indexOf(user_id);
          return o.splice(idx, 1);
        });
      }
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (writing.length) {
      timer = setTimeout(() => {
        setWriting([]);
      }, 3000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [writing]);

  return writing;
}

function updateUsersOnlineStatus(
  users: IUser[],
  { user_id, action }: WebSocketOfflineAction | WebSocketOnlineAction
) {
  return users.map((user) => {
    if (user.id === user_id) {
      return { ...user, online: action === "online" };
    }
    return user;
  });
}

/**
 * conversation_id needs to be in params
 */
const useGetConversationData = () => {
  const params = useParams();

  const { queryKey: conversationsQk, data: conversations } = useConversations();
  const convo = conversations?.find(
    (convo) => contextPathMap[CONTEXT][convo.context] === params.conversation_id
  );
  const title: string | undefined = convo?.context
    ? contextTitleMap[CONTEXT][convo?.context]
    : undefined;

  const contact = convo?.contact;
  const users: IUser[] = convo?.users || [];
  const context = convo?.context || "agent";
  const write = convo?.write || false;

  const sale_id = parseInt(params.sale_id || "-1", 10);
  const qk = useRef(conversationsQk).current;

  const client = useQueryClient();

  const { data, queryKey, refetch } = useConversation(
    context,
    sale_id,
    (data) => {
      if (conversations) {
        const idx = [...conversations].findIndex((c) => c.context === context);
        conversations[idx] = {
          ...conversations[idx],
          unread: 0,
          can_unread: data.can_unread,
        };
        client.setQueryData(qk, { conversations });
      }
    }
  );

  const messages = data?.messages;

  return {
    title,
    contact,
    sale_id,
    context,
    users,
    write,
    queryKey,
    refetch,
    messages,
  };
};

export {
  useConversations,
  useConversation,
  useConversationMutation,
  useMarkConversationAsUnread,
  useWritingStatus,
  useGetConversationData,
};
