import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Header, VStack, Loading, UIText } from "therese";

import { ReactComponent as QuestionMark } from "symbols/20/question.svg";

import {
  Container,
  defaultVStackProps,
} from "../../../components/Layout/Layout";
import { Suspense } from "../../../components/Suspense";
import { lazy } from "react";

const Category = lazy(() => import("./Category"));

function CategoryHelp() {
  const location = useLocation();
  const { title } = (location.state as any) || {
    title: "Spørsmål og svar",
  };

  return (
    <Suspense
      fallback={
        <>
          <Helmet title={title} />
          <Header
            title="Spørsmål og svar"
            icon={<QuestionMark />}
            linkAs={Link}
            previous={{ title: "Tilbake til oversikt", to: ".." }}
          />

          <Container>
            <VStack {...defaultVStackProps}>
              <UIText size="28" as="h3">
                {title}
              </UIText>
              <Loading />
            </VStack>
          </Container>
        </>
      }
    >
      <Category />
    </Suspense>
  );
}

export default CategoryHelp;
