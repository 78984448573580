import { ILogoProps } from "../types";

import { ReactComponent as LogoHorizontal } from "./logo_horizontal.svg";
import { ReactComponent as LogoVertical } from "./logo_vertical.svg";
import { ReactComponent as LogoCentered } from "./logo_centered.svg";

import * as styles from "./styles.module.css";

function Logo({ largeScreen = true, centered = false }: ILogoProps) {
  return centered ? (
    <LogoCentered className={styles.logo} aria-hidden />
  ) : largeScreen ? (
    <LogoVertical className={styles.logo} aria-hidden />
  ) : (
    <LogoHorizontal className={styles.logo} aria-hidden />
  );
}

export { Logo };
