import {
  useQuery,
  useMutation,
  useQueryClient,
  UseQueryOptions,
  QueryObserverResult,
} from "react-query";
import redaxios from "redaxios";
import { useNavigate } from "react-router-dom";

type ErrorResponse<T extends PortalContext> = T extends "user"
  ? IGenericErrorResponse
  : IAgentProfileErrorResponse;

function useProfile<T extends PortalContext = typeof CONTEXT>(
  type?: T,
  options?: UseQueryOptions<IUser, ErrorResponse<T>>
): QueryObserverResult<IUser, ErrorResponse<T>> {
  return useQuery<IUser, ErrorResponse<T>>(
    profileEndpoint(type || CONTEXT),
    options
  );
}

function useProfileMutation(type: PortalContext = CONTEXT) {
  const client = useQueryClient();
  const navigate = useNavigate(); // Get the navigate function from React Router
  const queryKey = profileEndpoint(type);

  // Define redirect url from portal or subdomain or portal
  const match = window.location.hostname.match(/^.*portal\.(.+\..+)$/);

  const redirectToTarget = () => {
    if (match) {
      const targetUrl = `https://portal.${match[1]}`;
      console.log(`Redirecting to ${targetUrl}`);
      window.location.replace(targetUrl);
    } else {
      console.log(`Redirecting to root /`);
      navigate("/", { replace: true });
    }
    console.log("location.href: " + document.location.href);
  };

  return useMutation(() => redaxios.delete(`/api/${queryKey}`), {
    onSuccess: () => {
      client.setQueryData(queryKey, undefined); // clear profile
      redirectToTarget();
    },
    onError: (error) => {
      console.warn("Unexpected response while logging out", error);
      client.setQueryData(queryKey, undefined); // clear profile
      redirectToTarget();
    },
  });
}

const profileEndpoint = (type: PortalContext) => {
  return `${type === "agent" ? "agents" : "v2"}/profile`;
};

export { useProfile, useProfileMutation, profileEndpoint };
