import { ReactNode } from "react";
import { Helmet } from "react-helmet-async";
import cx from "classnames";

import { UIText, VStack } from "therese";

import { Suspense } from "../Suspense";
import { Container } from "./Container/Container";
import type { IContainerProps } from "./Container/Container";

import * as styles from "./styles.module.css";

interface IDefaultProps {
  title: string;
  aside?: ReactNode;
  children: ReactNode;
  width?: "default" | "narrow" | "full";
}

const defaultVStackProps: { spacing: "l"; responsive: boolean } = {
  spacing: "l",
  responsive: true,
};

function Default(props: IDefaultProps) {
  return (
    <>
      <Helmet title={props.title} />
      <Container width={props.width}>
        <VStack spacing="m" responsive includeLastChild>
          <div
            className={cx({
              [styles.header]: props.aside,
            })}
          >
            <UIText className={styles.center} as="h2" size="28">
              {props.title}
            </UIText>

            {props.aside}
          </div>
        </VStack>
        <VStack {...defaultVStackProps}>
          <Suspense>{props.children}</Suspense>
        </VStack>
      </Container>
    </>
  );
}

export { Container, Default, defaultVStackProps };
export type { IContainerProps };
