import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import isToday from "dayjs/plugin/isToday";
import isTomorrow from "dayjs/plugin/isTomorrow";
import isYesterday from "dayjs/plugin/isYesterday";
import relativeTime from "dayjs/plugin/relativeTime";
import toObject from "dayjs/plugin/toObject";
dayjs.extend(isYesterday);
dayjs.extend(isTomorrow);
dayjs.extend(isToday);
dayjs.extend(weekday);
dayjs.extend(relativeTime);
dayjs.extend(toObject);

export default dayjs;
