import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { LoadingFullScreen } from "../../components/LoadingFullScreen";
import { useProperties } from "../../components/useProperties";
import Login from "../Login/Login";

function Root() {
  const navigate = useNavigate();
  const { data, isLoading } = useProperties();
  const { properties } = data || {};

  useEffect(() => {
    if (properties) {
      let property = properties[0];
      if (properties.length > 1) {
        const sortedPropertiesPhase = properties
          .filter((p) => p.phase > 0)
          .sort((p) => p.phase)
          .concat(properties.filter((p) => p.phase === 0));

        property = sortedPropertiesPhase[0];

        navigate(`/${property.sale_id}/boliger`, { replace: true });
      } else {
        navigate(`/${property.sale_id}`, { replace: true });
      }
    }
  }, [properties, navigate]);

  return isLoading ? <LoadingFullScreen /> : <Login />;
}

export default Root;
