type ContextMap = Record<"agent" | "user", Record<ContextTypes, string>>;

const contextPathMap: ContextMap = {
  user: {
    agent: "megler",
    agent_buy: "megler",
    photographer: "fotograf",
    takst: "takstperson",
    specialist_buyer: "saksbehandler_for_kjoper",
    specialist_seller: "saksbehandler_for_selger",
    specialist_broker: "spesialist_og_megler",
  },
  agent: {
    agent: "selger",
    agent_buy: "kjoper",
    photographer: "fotograf",
    takst: "takstperson",
    specialist_buyer: "saksbehandler_for_kjoper",
    specialist_seller: "saksbehandler_for_selger",
    specialist_broker: "spesialist_og_megler",
  },
};

const contextTitleMap: ContextMap = {
  user: {
    agent: "Megler",
    agent_buy: "Megler",
    photographer: "Fotograf",
    takst: "Takstperson",
    specialist_buyer: "Saksbehandler for kjøper",
    specialist_seller: "Saksbehandler for selger",
    specialist_broker: "Saksbehandler for megler",
  },
  agent: {
    agent: "Selger",
    agent_buy: "Kjøper",
    photographer: "Fotograf",
    takst: "Takstperson",
    specialist_buyer: "Saksbehandler for kjøper",
    specialist_seller: "Saksbehandler for selger",
    specialist_broker: "Saksbehandler for megler",
  },
};

const userTypeMap: Record<UserType, string> = {
  agent: "Megler",
  user: "Eier",
  buyer: "Kjøper",
  specialist_buyer: "Saksbehandler for kjøper",
  specialist_seller: "Saksbehandler for selger",
  specialist_broker: "Saksbehandler for megler",
};

export { contextPathMap, contextTitleMap, userTypeMap };
