import { useRef } from "react";
import { useLocation, useParams } from "react-router-dom";

function useClosePath(fallback?: string): string {
  const location = useLocation();
  const { sale_id } = useParams();
  let fallbackPath = `/${sale_id}`;
  if (fallback !== undefined) {
    fallbackPath = fallbackPath + fallback;
  }

  const { from } = (location.state as any) || {
    from: { pathname: fallbackPath },
  };

  const closePath = useRef(from.pathname).current;

  return closePath;
}

export { useClosePath };
