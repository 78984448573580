import { ReactNode } from "react";
import { Link as RouteLink } from "react-router-dom";
import cx from "classnames";

import { Link } from "therese";

import { ReactComponent as ChevronLeft } from "symbols/chevron_left.svg";

import * as styles from "./styles.module.css";

interface IHeaderProps {
  children: ReactNode;
  back: boolean;
  spacing?: boolean;
  doubleBack?: boolean;
}

function Header(props: IHeaderProps) {
  return (
    <header
      className={cx(styles.header, {
        [styles.spacing]: props.spacing,
      })}
    >
      {props.back && (
        <Link
          as={RouteLink}
          to={props.doubleBack ? "../../" : ".."}
          theme="outline"
          icon={ChevronLeft}
          className={styles.back}
          hideChildren
        >
          Tilbake
        </Link>
      )}
      {props.children}
    </header>
  );
}

export { Header };
