import { useEffect, RefObject, useCallback } from "react";

function useExpandWithContent(ref: RefObject<HTMLTextAreaElement>) {
  const adjustTextArea = useCallback(() => {
    const textarea = ref.current;
    if (textarea) {
      textarea.style.height = "inherit";
      const { height } = textarea.getBoundingClientRect();
      textarea.style.height = `${Math.max(height, textarea.scrollHeight)}px`;
    }
  }, [ref]);

  useEffect(() => {
    const textarea = ref.current;

    if (textarea) {
      textarea.addEventListener("keyup", adjustTextArea);
      return (): void => {
        textarea.removeEventListener("keyup", adjustTextArea);
      };
    }
  }, [ref]);

  return { readjust: adjustTextArea };
}

function useVisualViewportChange() {
  useEffect(() => {
    function handleResize(event: any) {
      if (window.visualViewport.scale === 1) {
        const root = document.querySelector<HTMLDivElement>(".root");
        if (root) {
          root.style.height = (event.target.height as number) + "px";
        }
        setTimeout(() => {
          document.body.scrollIntoView();
        }, 0);
      }
    }

    if (window.visualViewport !== undefined) {
      window.visualViewport.addEventListener("resize", handleResize);
    }
    return () => {
      if (window.visualViewport !== undefined) {
        window.visualViewport.removeEventListener("resize", handleResize);
      }
    };
  }, []);
}

export { useExpandWithContent, useVisualViewportChange };
