// extracted by mini-css-extract-plugin
var _1 = "Mj58XMUDD46jicyyGfq5";
var _2 = "zWTsHZFPRKtqbUdjD3L9";
var _3 = "cXg0TZZc9zSaIwtNK5Ch";
var _4 = "Run0kDY5u6BcjK4JOAQF";
var _5 = "_riLgD497SzJhjT1TxP2";
var _6 = "Xvp6DqEaOTOvXHUWYNcA";
var _7 = "xt1BDeM5KlupFWPDvJmR";
var _8 = "Suy3hKvgCukbbNW4zeIG";
var _9 = "W9qVcLewvjC1hCWh85Sy";
var _a = "P7uXNyneA264Su4EA6jj";
var _b = "dkDsJalkclgsjKamGvDh";
var _c = "opx5Ve8qyy_HeUrW2sVr";
export { _1 as "addButton", _2 as "backButton", _3 as "backButtonContainer", _4 as "backdrop", _5 as "body", _6 as "buttons", _7 as "favoriteTemplates", _8 as "form", _9 as "root", _a as "tabs", _b as "templatesContainer", _c as "title" }
