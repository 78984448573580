import { lazy, Suspense } from "react";

import { useProfile } from "./components/useProfile";
import { Route } from "./components/Route";
import { Default as DefaultLayout } from "./components/Layout/Layout";
import { LoadingFullScreen } from "./components/LoadingFullScreen";
import { Task } from "./components/CMSTask/CMSTask";

import { Layout, MediaLayout } from "./Layout.user";
import Overview from "./views/Overview/Overview";
import Messages from "./views/Messages/Messages";
import Category from "./views/Help/Category";
import AddLead from "./views/AddLead";
import GuideView from "./views/Guide/GuideView";
import Photos from "./views/Photos";
import { usePortalQuery } from "./components/usePortalQuery";
import { Routes } from "react-router-dom";
import { useProperties } from "./components/useProperties";
import { AccessModeEnum } from "./utils/enums";
import { Tasks } from "./views/Tasks/Tasks";

const Profile = lazy(() => import("./views/Profile"));
const Services = lazy(() => import("./views/Services/Services"));
const Properties = lazy(() => import("./views/Properties"));
const Documents = lazy(() => import("./views/Documents"));
const Document = lazy(() => import("./views/Document"));
const Help = lazy(() => import("./views/Help/Help"));
const PropertyView = lazy(() => import("./views/Property/Property"));
const Notifications = lazy(() => import("./views/Notifications/Notifications"));
const Events = lazy(() => import("./views/Events/Events"));
const Login = lazy(() => import("./views/Login/Login"));

function App() {
  const { data: user, isLoading, isError } = useProfile();

  const {
    data,
    isLoading: propIsLoading,
    isError: propIsError,
  } = usePortalQuery<IProperty, { msg: string; code: string }>("v2/property");

  const { isLoading: propertiesLoading } = useProperties({ enabled: false }); // they are not needed here, but we check if loading

  const authenticated =
    !isError && !propIsError && Boolean(Object.keys(user || {}).length);

  const notProjectUnit =
    !(
      data?.project_relation === "ProjectUnit" &&
      data?.mode === AccessModeEnum.BUY
    ) && !propIsLoading;

  const isNotSpouse = data?.mode !== "spouse" && !propIsLoading;

  if (isLoading || propIsLoading || propertiesLoading) {
    return <LoadingFullScreen />;
  }

  if (!authenticated && propIsError && !propIsLoading) {
    return (
      <Suspense fallback={<LoadingFullScreen />}>
        <Login />
      </Suspense>
    );
  }

  const isSpouse = data?.mode === AccessModeEnum.SPOUSE;

  return isSpouse ? (
    <SpouseRoutes />
  ) : (
    <Routes>
      <Route
        path="bilder/*"
        element={
          <MediaLayout title="Bilder">
            <Photos />
          </MediaLayout>
        }
      />
      <Route path="/" element={<Layout />}>
        <Route index element={<Overview />} />
        <Route path="bolig" element={<PropertyView />} />
        <Route path="oppgaver">
          <Route
            index
            element={
              <DefaultLayout title="Alle oppgaver">
                <Tasks />
              </DefaultLayout>
            }
          />
          <Route path=":task_id/*" element={<Task />} />
        </Route>
        <Route path="dokumenter/*" element={<Documents />} />
        <Route path="dokument">
          <Route path=":documentId/*" element={<Document />} />
        </Route>
        {notProjectUnit && isNotSpouse && (
          <Route
            path="meldinger/*"
            element={
              <Messages showMarkUnread hideView={user?.type === "agent"} />
            }
          />
        )}
        <Route path="hjelp">
          {notProjectUnit && isNotSpouse && (
            <Route
              index
              element={
                <DefaultLayout title="Spørsmål og guider">
                  <Help />
                </DefaultLayout>
              }
            />
          )}
          <Route path=":category" element={<Category />} />
          <Route path="guider/:guideId" element={<GuideView />} />
        </Route>
        {notProjectUnit && <Route path="varsler" element={<Notifications />} />}
        {notProjectUnit && <Route path="avtaler/*" element={<Events />} />}
        {isNotSpouse && <Route path="fordeler/*" element={<Services />} />}
        <Route path="profil" element={<Profile />} />
        <Route
          path="boliger"
          element={
            <DefaultLayout title="Dine boliger">
              <Properties />
            </DefaultLayout>
          }
        />
        <Route path="legg-til-interessent" element={<AddLead />} />
      </Route>
    </Routes>
  );
}

function SpouseRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Overview />} />
        <Route path="oppgaver">
          <Route
            index
            element={
              <DefaultLayout title="Alle oppgaver">
                <Tasks isSpouse={true} />
              </DefaultLayout>
            }
          />
          <Route path=":task_id/*" element={<Task />} />
        </Route>
        <Route path="dokumenter/*" element={<Documents />} />
        <Route path="dokument">
          <Route path=":documentId/*" element={<Document />} />
        </Route>
        <Route path="hjelp">
          <Route
            index
            element={
              <DefaultLayout title="Spørsmål og guider">
                <Help />
              </DefaultLayout>
            }
          />
          <Route path=":category" element={<Category />} />
          <Route path="guider/:guideId" element={<GuideView />} />
        </Route>
        <Route path="profil" element={<Profile />} />
        <Route
          path="boliger"
          element={
            <DefaultLayout title="Dine boliger">
              <Properties />
            </DefaultLayout>
          }
        />
      </Route>
    </Routes>
  );
}

export default App;
