// extracted by mini-css-extract-plugin
var _1 = "ELwFJ6l8cRKutFopNmlV";
var _2 = "xI7ef5rkx1Gp9H2raS7c";
var _3 = "SakfAm38QJz_0G8Ce59K";
var _4 = "U5rp_P7U5zLRJu7Gbe25";
var _5 = "hSo0Avx4EGozj9oZJ3we";
var _6 = "S0_zd5O3DOtEWw9KH66G";
var _7 = "G260HBfc0LBbjRzCdqWw";
var _8 = "G_2aZO0oMJyS_bO8UdPU hSo0Avx4EGozj9oZJ3we";
var _9 = "K_Sm_FroyvXzJhzdI51P hSo0Avx4EGozj9oZJ3we";
var _a = "FMQqJ415OnFdvna0tgAM hSo0Avx4EGozj9oZJ3we";
var _b = "Q5DAam9rjECSHB1AQYWg hSo0Avx4EGozj9oZJ3we";
export { _1 as "paddingL", _2 as "paddingM", _3 as "paddingS", _4 as "paddingXL", _5 as "root", _6 as "spacing", _7 as "title", _8 as "width_default", _9 as "width_full", _a as "width_large", _b as "width_narrow" }
