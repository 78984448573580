import { ElementType } from "react";

import { ReactComponent as House } from "symbols/20/house.svg";
import { ReactComponent as Calendar } from "symbols/20/calendar.svg";
import { ReactComponent as Message } from "symbols/20/message.svg";
import { ReactComponent as User } from "symbols/20/user.svg";
import { ReactComponent as Tasks } from "symbols/circle_check.svg";
import { ReactComponent as Question } from "symbols/20/question.svg";
import { ReactComponent as Document } from "symbols/20/folder.svg";
import { ReactComponent as Services } from "symbols/star.svg";

export interface IPath {
  id: string;
  title: string;
  paths: {
    title: string;
    to: string;
    icon: ElementType;
    id: string;
    end?: boolean;
    pathSuffix?: boolean;
  }[];
}

const userPaths: IPath[] = [
  {
    id: "main_navigation",
    title: "Hovednavigasjon",
    paths: [
      { title: "Oversikt", to: "", icon: House, end: true, id: "home" },
      { title: "Oppgaver", to: "oppgaver", icon: Tasks, id: "tasks" },
      { title: "Meldinger", to: "meldinger", icon: Message, id: "messages" },
      {
        title: "Dokumenter",
        to: "dokumenter",
        icon: Calendar,
        id: "documents",
      },
      { title: "Spørsmål og guider", to: "hjelp", icon: Question, id: "faq" },
      {
        title: "Fordeler",
        to: "fordeler",
        icon: Services,
        id: "services",
      },
    ],
  },
  {
    id: "user_navigation",
    title: "Brukernavigasjon",
    paths: [
      { title: "Din profil", to: "profil", icon: User, id: "profil" },
      { title: "Dine boliger", to: "boliger", icon: House, id: "properties" },
    ],
  },
];

const agentPaths: IPath[] = [
  {
    id: "main_navigation",
    title: "Hovednavigasjon",
    paths: [
      { title: "Oppdrag", to: "oppdrag", icon: House, end: true, id: "home" },
      { title: "Oppgaver", to: "oppgaver", icon: Tasks, id: "tasks" },
      {
        title: "Avtaler",
        to: "avtaler",
        icon: Calendar,
        id: "appointments",
      },
      { title: "Meldinger", to: "meldinger", icon: Message, id: "messages" },
      {
        title: "Dokumenter",
        to: "dokumenter",
        icon: Document,
        id: "documents",
      },
    ],
  },
  {
    id: "user_navigation",
    title: "Brukernavigasjon",
    paths: [
      {
        title: "Din profil",
        to: "profil",
        icon: User,
        id: "profile",
        pathSuffix: false,
      },
    ],
  },
];

const spousePaths: IPath[] = [
  {
    id: "main_navigation",
    title: "Hovednavigasjon",
    paths: [
      { title: "Oversikt", to: "", icon: House, end: true, id: "home" },
      { title: "Oppgaver", to: "oppgaver", icon: Tasks, id: "tasks" },
      {
        title: "Dokumenter",
        to: "dokumenter",
        icon: Document,
        id: "documents",
      },
      { title: "Spørsmål og guider", to: "hjelp", icon: Question, id: "faq" },
    ],
  },
  {
    id: "user_navigation",
    title: "Brukernavigasjon",
    paths: [
      { title: "Din profil", to: "profil", icon: User, id: "profil" },
      { title: "Dine boliger", to: "boliger", icon: House, id: "properties" },
    ],
  },
];

export { userPaths, agentPaths, spousePaths };
