import { StrictMode } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Helmet, HelmetProvider } from "react-helmet-async";

import "./styles/main.css";
import "therese/dist/therese.cjs.production.min.css";

import { defaultQueryFunction } from "./utils/defaultQueryFn";
import { SkipToMain } from "./components/SkipToMain";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFunction,
      retry: 0,
      refetchOnWindowFocus: false,
    },
  },
});

interface IRootProps {
  title: string;
  children: React.ReactNode;
}

function Root({ title, children }: IRootProps) {
  return (
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <Helmet defaultTitle={title} titleTemplate={`%s | ${title}`} />
          <SkipToMain />
          {children}
        </HelmetProvider>
      </QueryClientProvider>
    </StrictMode>
  );
}

export { Root };
