import redaxios from "redaxios";
import { ICMSTask } from "./CMSTask/types/tasks";
import { usePortalParams } from "./usePortalParams";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useIsValuation } from "./useIsValuation";

interface ITasksResponse {
  tasks: ITaskResponse[];
}

interface ITaskResponse {
  id: string;
  slug: string;
  locked: boolean;
  completed: boolean;
  deadline_date?: string;
  phase: Phase;
  status: boolean;
  type: "task" | "guide";
}

interface IGroupedTasks {
  title?: string;
  phase: Phase;
  tasks: ITask[];
}

export interface ICMSTasksResponse {
  data: ICMSTask[];
}

export interface ITask extends Omit<ITaskResponse, "deadline_date"> {
  title: string;
  deadline_date?: Date;
  cmsTask?: ICMSTask;
}

const useCMSTasks = () => {
  const params = useParams();
  const sale_id = parseInt(params.sale_id || "-1", 10);
  const { mode } = usePortalParams();
  const queryKey = ["tasks", sale_id, mode];
  const isValuation = useIsValuation();

  const tasksQuery = useQuery<ITasksResponse>(
    queryKey,
    async () => {
      const resp = await redaxios.get<ITasksResponse>(
        `/api/tasks?mode=${mode}&sale_id=${sale_id}`
      );
      return resp.data;
    },
    {
      staleTime: 3600000,
      enabled: !!(sale_id && mode),
    }
  );

  const cmsTasksQuery = useQuery<ICMSTasksResponse>(
    ["cms/tasks", sale_id, mode],
    async () => {
      const resp = await redaxios.get<ICMSTasksResponse>(
        `/api/cms/tasks?mode=${mode}&sale_id=${sale_id}`
      );
      return resp.data;
    },
    {
      staleTime: 3600000,
      enabled: !!(sale_id && mode),
    }
  );

  const cmsTasks = cmsTasksQuery.data?.data
    ?.map((cmsTask) => {
      const { displayName: title, displaySlug, slug } = cmsTask;
      const task = tasksQuery.data?.tasks.find((task) => task.slug === slug);

      const listTask: ITask | undefined = task
        ? {
            id: task.slug,
            title: title || task.slug,
            slug: displaySlug || slug,
            locked: title === undefined || task.locked,
            completed: task.completed,
            deadline_date: task.deadline_date
              ? new Date(task.deadline_date)
              : undefined,
            status: task.status,
            phase: task.phase,
            type: task.type,
            cmsTask,
          }
        : undefined;

      return listTask;
    })
    .filter((task) => !!task && (!isValuation || task.phase === 0)) as ITask[];

  return { tasksQuery, cmsTasksQuery, queryKey, cmsTasks };
};

const groupTaskByPhase = (tasks?: Array<ITask>) => {
  let groupedTasks: IGroupedTasks[] = [];
  if (tasks !== undefined) {
    const _tasks: Record<string, IGroupedTasks> = {};
    const status: ITask[] = [];
    tasks.forEach((task) => {
      const phase = task.phase;
      if (_tasks[phase] === undefined) {
        _tasks[phase] = {
          phase,
          tasks: [],
        };
      }
      _tasks[phase].tasks.push(task);
      if (task.status) {
        status.push(task);
      }
    });
    groupedTasks = Object.values(_tasks);
  }

  return groupedTasks;
};

export { groupTaskByPhase, useCMSTasks };
