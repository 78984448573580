import { Button } from "therese";

import * as styles from "./styles.module.css";

function SkipToMain() {
  return (
    <Button className={styles.root} as="a" href="#hovedinnhold">
      Hopp til hovedinnhold
    </Button>
  );
}

export { SkipToMain };
