import { AssignmentType } from "../utils/enums";
import { useAssignmentType } from "./useAssignmentType";
import { usePortalQuery } from "./usePortalQuery";

export const useIsValuation = () => {
  const assignmentType = useAssignmentType();
  const { data: propertyData, isLoading } =
    usePortalQuery<IProperty>("v2/property");
  const isValuation =
    assignmentType === AssignmentType.VALUATION &&
    !isLoading &&
    !!propertyData &&
    propertyData.phase <= 1;
  return isValuation;
};
