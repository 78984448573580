import redaxios, { Options } from "redaxios";
import { QueryFunctionContext } from "react-query";

async function defaultQueryFunction(context: QueryFunctionContext) {
  const [key, options = {}] = context.queryKey as [string, Options];

  try {
    const { data } = await redaxios.get(`/api/${key}`, options);
    return data;
  } catch (error) {
    throw error.data;
  }
}

export { defaultQueryFunction };
