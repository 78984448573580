import { useQuery, useQueryClient, UseQueryOptions } from "react-query";

function useProperties(options?: UseQueryOptions) {
  const client = useQueryClient();
  return useQuery<IPropertiesResponse>("v2/properties", {
    onSuccess: ({ properties }) => {
      properties.forEach((property) => {
        const data = client.getQueryData<IProperty>([
          "v2/property",
          {
            params: {
              sale_id: property.sale_id,
              mode: property.mode,
            },
          },
        ]);

        client.setQueryData(
          [
            "v2/property",
            {
              params: {
                sale_id: property.sale_id,
                mode: property.mode,
              },
            },
          ],
          { ...property, ...data }
        );
      });
    },
    ...(options || {}),
  });
}

export { useProperties };
