import { createRoot } from "react-dom/client";
import { Root } from "./Root";

import App from "./App.user";
import { Suspense } from "react";
import { LoadingFullScreen } from "./components/LoadingFullScreen";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { default as RootView } from "./views/Root";
import { FocusHandler } from "./components/FocusHandler";
import PortalBoundary from "./PortalBoundary";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Root title={SITE_NAME}>
    <Suspense fallback={<LoadingFullScreen />}>
      <BrowserRouter>
        <FocusHandler />
        <Routes>
          <Route path="/" element={<RootView />} />
          <Route
            path=":sale_id/*"
            element={
              <PortalBoundary>
                <App />
              </PortalBoundary>
            }
          />
        </Routes>
      </BrowserRouter>
    </Suspense>
  </Root>
);
