export enum PhaseEnum {
  "request",
  "preparation",
  "forSale",
  "sold",
  "reserved",
  "archived",
  "expired",
  "terminated",
}

export enum AssignmentType {
  "SALE" = 0,
  "RENT" = 1,
  "VALUATION" = 2,
  "SETTLEMENT" = 3,
  "FORECLOSURE" = 4,
}

export enum AccessModeEnum {
  SELL = "sell",
  BUY = "buy",
  SPOUSE = "spouse",
}
