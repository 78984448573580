import {
  createContext,
  useContext,
  useEffect,
  Suspense,
  lazy,
  useState,
  ReactNode,
} from "react";

import { useProperties } from "./components/useProperties";
import { useProfile } from "./components/useProfile";
import { usePortalQuery } from "./components/usePortalQuery";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const AgentNotification = lazy(() => import("./components/AgentNotification"));

/**
 * Replaces assignment number and estate id to the actual sale_id,
 * if those are passed as the sale_id parameter in the URL
 */
const useReplaceSaleId = (property: IAbbrProperty, probablySaleId: string) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      property &&
      property.sale_id &&
      String(property.sale_id) !== probablySaleId
    ) {
      const includesUpper = pathname.includes(probablySaleId.toUpperCase());

      if (includesUpper)
        navigate(
          pathname.replace(
            probablySaleId.toUpperCase(),
            String(property.sale_id)
          )
        );
      else
        navigate(pathname.replace(probablySaleId, String(property.sale_id)), {
          replace: true,
        });
    }
  }, [property]);
};

const PortalContext = createContext<{ mode?: AccessMode }>({ mode: undefined });
function PortalBoundary(props: { children: ReactNode }) {
  const params = useParams();
  const { data: { properties } = {} } = useProperties();

  // sale_id parameter can be assignment number or estate id,
  // and in those cases it needs to be changed to the actual sale id before using it.
  const probablySaleId = String(params.sale_id).toLowerCase();

  const property = properties?.find((property) => {
    return (
      String(property.sale_id) === probablySaleId ||
      String(property.estate_id).toLowerCase() === probablySaleId ||
      String(property.assignment_number).toLowerCase() === probablySaleId
    );
  });

  useReplaceSaleId(property, probablySaleId);

  const { data: user } = useProfile();

  const { data } = usePortalQuery<IProperty, { msg: string; code: string }>(
    "v2/property",
    { enabled: user?.type === "agent" } // only agent needs this API to be able to get user mode
  );

  /*
   ** Allows to override mode
   ** This is for the agent to be able to see the user portal as both seller and buyer
   */
  const [modeOverride, setModeOverride] = useState<AccessMode | null>(null);
  useEffect(() => {
    if (user?.type === "agent") {
      const mode = new URLSearchParams(window.location.search).get(
        "mode"
      ) as AccessMode | null;
      setModeOverride(mode);
    }
  }, [user]);

  const mode = modeOverride || property?.mode || data?.mode;

  return (
    <PortalContext.Provider value={{ mode }}>
      {user?.type === "agent" && (
        <Suspense fallback={null}>
          <AgentNotification mode={mode} />
        </Suspense>
      )}
      {props.children}
    </PortalContext.Provider>
  );
}

function usePortalContext() {
  return useContext(PortalContext);
}

export default PortalBoundary;
export { usePortalContext };
