const badges = [
  "",
  "Kommer for salg",
  "Til salg",
  "Solgt",
  "Reservert",
  "Arkivert",
];

const PhasesArray: string[] = [
  "request",
  "preparation",
  "forSale",
  "sold",
  "reserved",
  "archived",
  "expired",
  "terminated",
];

const agentPhaseFilter = {
  name: "phase",
  type: "checkbox",
  items: [
    { label: "Kom i gang", value: "0" },
    { label: badges[1], value: "1" },
    { label: badges[2], value: "2" },
    { label: badges[3], value: "3" },
    { label: badges[5], value: "5" },
  ],
};

export { badges as phaseBadges, agentPhaseFilter, PhasesArray };
