import { useEffect, useRef } from "react";
import useReactWebSocket from "react-use-websocket";

interface IUseWebSocket {
  filter?: (message: WebSocketActions) => boolean;
}

function useWebSocket(options?: IUseWebSocket) {
  const stale = useRef(false);

  useEffect(() => {
    return () => {
      stale.current = true;
    };
  }, []);

  return useReactWebSocket(WS_URL, {
    share: true,
    onOpen: () => console.log("open WebSocket: " + WS_URL),
    shouldReconnect: () => {
      return !stale.current;
    },
    reconnectAttempts: 0,
    reconnectInterval: 3000,
    filter: (message) => {
      if (options && options.filter) {
        const data = JSON.parse(message.data) as WebSocketActions;
        return options.filter(data);
      }
      return true;
    },
  });
}

export { useWebSocket };
