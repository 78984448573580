import { Suspense, lazy, useCallback, useEffect } from "react";
import { Helmet } from "react-helmet-async";

import { arrayToSentence, AvatarGroup, Loading, Person } from "therese";

import { useWebSocket } from "../../../components/useWebSocket";
import { useProfile } from "../../../components/useProfile";
import { Container } from "../../../components/Layout/Layout";
import { View } from "../../../components/SplitView";

import {
  useConversationMutation,
  useGetConversationData,
  useMessageDraftMutation,
  useDeleteMessageDraftMutation,
} from "../hooks";
import Header from "./Header";
import MessageList from "./MessageList";
import Form from "./Form/Form";

import * as styles from "./styles.module.css";
import { contextTitleMap } from "../utils";
import { ContextTypes, IUser } from "../../../api";

const WritingStatus = lazy(() => import("./WritingStatus"));

interface IContactProps {
  context: ContextTypes;
  contact: IUser[];
  children?: React.ReactNode | React.ReactNode[];
}

export function Contact(props: IContactProps) {
  return (
    <Person
      title={arrayToSentence(
        props.contact.map((u) => u.preferred_name || u.full_name)
      )}
      subTitle={contextTitleMap[CONTEXT][props.context]}
      avatar={
        <AvatarGroup
          avatars={props.contact.map(({ avatar, online }) => ({
            src: avatar,
            online,
          }))}
        />
      }
    >
      {props.children}
    </Person>
  );
}

function Conversation() {
  const conversationData = useGetConversationData();
  const { sendJsonMessage } = useWebSocket();
  const { data: user } = useProfile();
  const { mutate } = useConversationMutation(
    conversationData.context,
    conversationData.queryKey
  );

  const draftMutate = useMessageDraftMutation(
    conversationData.context,
    conversationData.queryKey
  );

  const deleteDraftMutate = useDeleteMessageDraftMutation(
    conversationData.context,
    conversationData.queryKey
  );

  const handleChange = useCallback(() => {
    sendJsonMessage({
      action: "writing",
      data: {
        sale_id: conversationData.sale_id,
        context: conversationData.context,
      },
    });
  }, [sendJsonMessage, conversationData.context, conversationData.sale_id]);

  /* postMessage from SW */
  useEffect(() => {
    function onMessage(event: MessageEvent) {
      if (event.data.action === "invalidate_conversation") {
        conversationData.refetch();
      }
    }

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", onMessage);

      return () => {
        navigator.serviceWorker.removeEventListener("message", onMessage);
      };
    }
  }, [conversationData]);

  if (user === undefined) {
    return (
      <Container>
        <Loading skipDelay />
      </Container>
    );
  }

  const currentUserID = user.id;
  return (
    <>
      <Helmet title={`Samtale med ${conversationData.title?.toLowerCase()}`} />
      <View
        header={
          <Header
            contactPerson={
              <Contact
                context={conversationData.context}
                contact={conversationData.contact || []}
              />
            }
            users={conversationData.users}
          />
        }
      >
        <div className={styles.messages}>
          <Container>
            <MessageList
              onRetry={mutate}
              messages={conversationData.messages}
              users={conversationData.users}
              currentUserID={currentUserID}
            />
          </Container>
        </div>

        <Suspense fallback={null}>
          <WritingStatus
            className={styles.writingstatus}
            users={conversationData.users}
            currentUserID={currentUserID}
            sale_id={conversationData.sale_id}
          />
        </Suspense>

        <Form
          disabled={!conversationData.write}
          onSubmit={(content) => {
            mutate({
              content,
              user_id: currentUserID,
              id: Math.random(),
            });
            deleteDraftMutate.mutate();
          }}
          onSubmitDraft={(content) => {
            draftMutate.mutate({
              content,
            });
          }}
          deleteDraft={() => {
            deleteDraftMutate.mutate();
          }}
          onChange={handleChange}
          context={conversationData.context}
        />
      </View>
    </>
  );
}

export default Conversation;
