import { useMutation, useQueryClient } from "react-query";
import redaxios from "redaxios";
import { IUsePortalQueryResult } from "../../../../../components/usePortalQuery";
import { useProfile } from "../../../../../components/useProfile";
import { AnyTemplate, IMessageTemplateWithRights } from "./types";

interface ITemplateMutationsParams {
  favoritesQuery: IUsePortalQueryResult<IMessageTemplatesResponse, unknown>;
  personalQuery: IUsePortalQueryResult<IMessageTemplatesResponse, unknown>;
  cmsFavoritesQuery: IUsePortalQueryResult<
    ICMSMessageTemplateFavoritesResponse,
    unknown
  >;
  cmsTemplatesQuery: IUsePortalQueryResult<
    ICMSMessageTemplateResponse,
    unknown
  >;
  userTemplates: IMessageTemplateWithRights[];
}

/** This should be used by useTemplates hook only. */
export const useTemplateMutations = ({
  favoritesQuery,
  personalQuery,
  cmsFavoritesQuery,
  cmsTemplatesQuery,
  userTemplates,
}: ITemplateMutationsParams) => {
  const client = useQueryClient();
  const { data: user } = useProfile(undefined, { staleTime: 60 * 1000 });

  const addFavoriteMutation = useMutation(
    (templateId: number) =>
      redaxios.post(`/api/agents/message_templates/favorites`, {
        template_id: templateId,
      }),
    {
      onSuccess: (_, templateId) => {
        client.setQueryData(favoritesQuery.queryKey, {
          templates: [
            userTemplates.find((template) => template.id === templateId),
            ...(favoritesQuery?.data?.templates || []),
          ],
        });
        client.invalidateQueries([personalQuery.queryKey]);
      },
    }
  );

  const addCMSFavoriteMutation = useMutation(
    (uid: string) =>
      redaxios.post(`/api/agents/message_templates/cms_favorites`, {
        cms_uid: uid,
      }),
    {
      onSuccess: (_, uid) => {
        client.setQueryData(cmsFavoritesQuery.queryKey, {
          favorites: [
            ...(cmsFavoritesQuery.data?.favorites || []),
            {
              user_id: user?.id || -1,
              cms_uid: uid,
            },
          ],
        });
        client.invalidateQueries([cmsTemplatesQuery.queryKey]);
      },
    }
  );

  const addFavorite = async (template: AnyTemplate) => {
    if (template.uid) await addCMSFavoriteMutation.mutateAsync(template.uid);
    else await addFavoriteMutation.mutateAsync(template.id as number);
  };

  const removeFavoriteMutation = useMutation(
    (templateId: number) =>
      redaxios.delete(`/api/agents/message_templates/favorites/${templateId}`),
    {
      onSuccess: (_, templateId) => {
        client.setQueryData(favoritesQuery.queryKey, {
          templates: favoritesQuery.data?.templates.filter(
            (template) => template.id !== templateId
          ),
        });
        client.invalidateQueries([personalQuery.queryKey]);
      },
    }
  );

  const removeCMSFavoriteMutation = useMutation(
    (uid: string) =>
      redaxios.delete(`/api/agents/message_templates/cms_favorites/${uid}`),
    {
      onSuccess: (_, uid) => {
        client.setQueryData(cmsFavoritesQuery.queryKey, {
          favorites: (cmsFavoritesQuery.data?.favorites || []).filter(
            (fav) => fav.cms_uid !== uid
          ),
        });
        client.invalidateQueries([cmsTemplatesQuery.queryKey]);
      },
    }
  );

  const removeFavorite = async (template: AnyTemplate) => {
    if (template.uid) await removeCMSFavoriteMutation.mutateAsync(template.uid);
    else await removeFavoriteMutation.mutateAsync(template.id as number);
  };

  const addTemplateMutation = useMutation(
    (template: { content: string; title: string }) =>
      redaxios.post<{ template: IMessageTemplate }>(
        `/api/agents/message_templates/personal`,
        template
      ),
    {
      onSuccess: (response) => {
        client.setQueryData(personalQuery.queryKey, {
          templates: [
            response.data.template,
            ...(personalQuery.data?.templates || []),
          ],
        });
      },
    }
  );

  const addTemplate = async (template: { content: string; title: string }) =>
    await addTemplateMutation.mutateAsync(template);

  const editTemplateMutation = useMutation(
    (template: { content: string; title: string; id: number }) =>
      redaxios<{ template: IMessageTemplate }>(
        `/api/agents/message_templates/personal`,
        {
          data: template,
          method: "PATCH",
        }
      ),
    {
      onSuccess: (response) => {
        client.setQueryData(personalQuery.queryKey, {
          templates: personalQuery.data?.templates.map((template) => {
            if (
              template.id.toString() === response.data.template.id.toString()
            ) {
              return response.data.template;
            } else return template;
          }),
        });
        client.setQueryData(favoritesQuery.queryKey, {
          templates: favoritesQuery.data?.templates.map((template) => {
            if (
              template.id.toString() === response.data.template.id.toString()
            ) {
              return response.data.template;
            } else return template;
          }),
        });
      },
    }
  );

  const editTemplate = async (template: {
    content: string;
    title: string;
    id: number;
  }) => await editTemplateMutation.mutateAsync(template);

  const deleteTemplateMutation = useMutation(
    (templateId: number) =>
      redaxios.delete(`/api/agents/message_templates/personal/${templateId}`),
    {
      onSuccess: (_, templateId) => {
        client.setQueryData(personalQuery.queryKey, {
          templates: personalQuery.data?.templates.filter(
            (template) => template.id !== templateId
          ),
        });
        client.setQueryData(favoritesQuery.queryKey, {
          templates: favoritesQuery.data?.templates.filter(
            (template) => template.id !== templateId
          ),
        });
      },
    }
  );

  const deleteTemplate = async (templateId: number) =>
    await deleteTemplateMutation.mutateAsync(templateId);

  return {
    addFavorite,
    removeFavorite,
    addTemplate,
    editTemplate,
    deleteTemplate,
  };
};
