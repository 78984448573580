import { useNavigate, useParams } from "react-router-dom";

import { VStack, Task, Loading, UIText } from "therese";

import { defaultVStackProps } from "../../components/Layout/Layout";
import { groupTaskByPhase, useCMSTasks } from "../../components/useTasks";
import { TaskLink } from "../../components/CMSTask/CMSTask";
import { PhaseBadge } from "../../components/PhaseBadge";
import React from "react";

export const Tasks = ({ isSpouse = false }) => {
  const { sale_id } = useParams();
  const { cmsTasksQuery, cmsTasks } = useCMSTasks();

  const { isLoading } = cmsTasksQuery;
  const groupedTasks = groupTaskByPhase(cmsTasks);
  const navigate = useNavigate();
  return (
    <VStack {...defaultVStackProps}>
      {isLoading ? (
        <Loading />
      ) : groupedTasks.length > 0 ? (
        groupedTasks.map((phase) => (
          <VStack spacing="m" key={phase.phase}>
            {phase.phase && (
              <VStack spacing="s">
                <PhaseBadge phase={phase.phase} />
              </VStack>
            )}
            <ul>
              <VStack spacing="s">
                {phase.tasks.map((task) => {
                  const disabled = task.completed || task.locked;
                  let link = task.slug;

                  let titleProps: {
                    as: React.ReactNode;
                    to?: string;
                    href?: string;
                  } = { as: TaskLink, to: link };

                  if (task.slug === "signer-oppdragsavtale") {
                    titleProps = {
                      as: "a",
                      href: `https://${ENDPOINT.replace(
                        "portal.",
                        ""
                      )}/komigang/bolig/${sale_id}`,
                    };
                    link = `https://${ENDPOINT.replace(
                      "portal.",
                      ""
                    )}/komigang/bolig/${sale_id}`;
                  }

                  if (task.slug === "fyll-ut-oppgjorsinstruks" && isSpouse) {
                    task.title = "Signer oppgjørsinstruks";
                  }

                  return (
                    <Task
                      as="li"
                      titleProps={disabled ? undefined : titleProps}
                      key={task.slug}
                      title={task.title}
                      completed={task.completed}
                      locked={task.locked}
                      deadline_date={!disabled ? task.deadline_date : undefined}
                      link={!disabled}
                      onClick={
                        !disabled
                          ? (event: React.MouseEvent<HTMLLIElement>): void => {
                              const anchor = event.target as HTMLAnchorElement;
                              if (anchor.nodeName !== "A") {
                                if (task.slug === "signer-oppdragsavtale") {
                                  window.location.href = link;
                                } else navigate(link);
                              }
                            }
                          : undefined
                      }
                    />
                  );
                })}
              </VStack>
            </ul>
          </VStack>
        ))
      ) : (
        <UIText size="16" as="p">
          Ingen oppgaver på dette tidspunktet.
        </UIText>
      )}
    </VStack>
  );
};
