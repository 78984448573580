import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { usePrevious } from "therese";

function FocusHandler() {
  const { pathname } = useLocation();
  const prevPathname = usePrevious(pathname);

  useEffect(() => {
    if (prevPathname && prevPathname !== pathname) {
      const element = document.querySelector("main");
      if (element) {
        element.focus();
        element.scrollTo(0, 0);
      }
    }
  }, [pathname, prevPathname]);

  return null;
}

export { FocusHandler };
