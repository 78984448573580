import React from "react";
import { Button } from "therese";
import { useTemplates } from "./hooks/useTemplates";
import * as styles from "./styles.module.css";

const FavoriteTemplates: React.FC<{
  onUseTemplate: (content: string) => void;
}> = ({ onUseTemplate }) => {
  const { isLoading, favoriteTemplates } = useTemplates();

  return !isLoading && favoriteTemplates.length ? (
    <ul className={styles.favoriteTemplates}>
      {favoriteTemplates.map((template) => (
        <li key={template.id || template.uid}>
          <Button size="small" onClick={() => onUseTemplate(template.content)}>
            {template.title}
          </Button>
        </li>
      ))}
    </ul>
  ) : null;
};

export default FavoriteTemplates;
