import { lazy, Suspense } from "react";

const Notifications = lazy(() => import("./PushNotifications"));

const supportsPushNotifications =
  "serviceWorker" in navigator && "PushManager" in window;

function PushNotifications() {
  if (EXPERIMENTAL_FEATURES.includes("push") && supportsPushNotifications) {
    return (
      <Suspense fallback={null}>
        <Notifications />
      </Suspense>
    );
  }

  return null;
}

export { PushNotifications as _experimental_PushNotifications };
