const address = {
  toDisplay(address: IAddress) {
    let display = address.street;
    if (address.number) {
      display = display + " " + address.number;
    }
    if (address.letter) {
      display = display + address.letter;
    }

    return display;
  },
  toPlace(address: IAddress) {
    return address.postcode + " " + address.city;
  },
  toAddress(address: IAddress) {
    return `${this.toDisplay(address)}, ${this.toPlace(address)}`;
  },
};

export { address };
