import cx from "classnames";
import { HTMLProps } from "react";

import * as styles from "./styles.module.css";

export interface IContainerProps extends HTMLProps<HTMLDivElement> {
  width?: "large" | "default" | "narrow" | "full";
  padding?: "s" | "m" | "l" | "xl";
  spacing?: boolean;
}

function Container({
  width = "default",
  spacing = true,
  padding,
  ...props
}: IContainerProps) {
  return (
    <div
      className={cx(styles[`width_${width}`], props.className, {
        [styles.spacing]: spacing,
        [styles.paddingS]: padding === "s",
        [styles.paddingM]: padding === "m",
        [styles.paddingL]: padding === "l",
        [styles.paddingXL]: padding === "xl",
      })}
    >
      {props.children}
    </div>
  );
}

export { Container };
