import { useRef, useEffect } from "react";

import { VStack, Message, EmptyState, Loading, IMessageProps } from "therese";

import { userTypeMap } from "../../utils";

interface IMessageListProps {
  messages?: IMessage[];
  onRetry: (message: IMessage) => void;
  users: IUser[];
  currentUserID: number;
}

function MessageList({ messages, ...props }: IMessageListProps) {
  const lastMessage = useRef<HTMLLIElement>(null);
  const initialUpdate = useRef(true);
  useEffect(() => {
    if (messages && messages.length) {
      requestAnimationFrame(() => {
        const opts: ScrollIntoViewOptions = {
          inline: "start",
          block: "nearest",
        };

        if (!initialUpdate.current) {
          opts["behavior"] = "smooth";
        }

        if (lastMessage.current) {
          lastMessage.current.scrollIntoView(opts);
        }

        if (initialUpdate.current) {
          initialUpdate.current = false;
        }
      });
    }
  }, [messages]);

  if (messages === undefined) {
    return <Loading />;
  }

  if (!messages.length) {
    return <EmptyState>Ingen meldinger enda...</EmptyState>;
  }

  return (
    <ol>
      <VStack spacing="m">
        {messages.map((message) => {
          const type =
            message.user_id !== props.currentUserID ? "received" : "sent";
          const user = props.users.find((u) => u.id === message.user_id);
          let { person }: Pick<IMessageProps, "person"> = {
            person: { name: "", title: "", avatar: null },
          };

          if (user !== undefined) {
            person = {
              name: user.preferred_name || user.full_name,
              title: userTypeMap[user.type],
              avatar: user.avatar,
            };
          }

          return (
            <li ref={lastMessage} key={message.id}>
              <Message
                created_date={
                  message.created_date
                    ? new Date(message.created_date)
                    : undefined
                }
                person={person}
                onRetry={() => props.onRetry(message)}
                type={type}
                error={message.error}
              >
                {message.content}
              </Message>
            </li>
          );
        })}
      </VStack>
    </ol>
  );
}

export default MessageList;
