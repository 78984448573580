import { lazy } from "react";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { Header, Loading } from "therese";
import { ReactComponent as Book } from "symbols/20/book.svg";

import { Container } from "../../components/Layout/Layout";
import { Suspense } from "../../components/Suspense";
import { useClosePath } from "../../components/CMSTask/useClosePath";
import { usePortalQuery } from "../../components/usePortalQuery";

const Guide = lazy(() => import("./Guide"));

function GuideView() {
  const params = useParams();

  const closePath = useClosePath("/hjelp");
  const { data: guide, isLoading } = usePortalQuery<ICMSGuide>(
    `cms/guide/${params.guideId}`
  );

  return (
    <>
      {guide && (
        <>
          <Helmet title={isLoading || !guide ? "" : guide.displayTitle} />
          <Header
            title={guide.displayTitle}
            linkAs={Link}
            icon={<Book />}
            close={{
              to: closePath,
              title: "Lukk",
            }}
          />
        </>
      )}

      <Container>
        <Suspense>
          {isLoading || !guide ? (
            <Loading skipDelay />
          ) : (
            <Guide guide={guide} />
          )}
        </Suspense>
      </Container>
    </>
  );
}

export default GuideView;
