import { UseQueryOptions } from "react-query";
import { usePortalQuery } from "./usePortalQuery";
import { compareDesc } from "date-fns";
import { dateFormatter } from "therese";
import dayjs from "./dayjs";
import { useCMSTasks } from "./useTasks";
import { AccessMode, IStatusResponse } from "../api";

const eventMapping = {
  "event-initial-meeting": "Besøk av megler",
  "event-valuer": "Besøk av takstperson",
  "event-photographer": "Besøk av fotograf",
  "event-showing": "Visning",
  "event-takeover": "Overtakelse",
};

export interface IEvent {
  slug: keyof typeof eventMapping;
  title?: string;
  name?: string;
  start_date: Date;
  end_date?: Date;
}

export interface INotification {
  date: Date;
  dateString: string;
  event?: IEvent;
  status?: StatusItem;
}

export interface IDayNotifications {
  notifications: INotification[];
  day: string;
}

interface IStatus extends Omit<IStatusResponse, "events"> {
  notifications: INotification[];
  daysWithNotifications: IDayNotifications[];
  events: IEvent[];
  badges: Record<string, number | undefined>;
}

function useStatus(
  opts?: { sale_id: number; mode?: AccessMode },
  options?: UseQueryOptions<IStatusResponse, unknown, IStatus>
) {
  let url = "v2/status";
  if (opts?.sale_id && opts.mode) {
    url = [url, { params: { sale_id: opts.sale_id, mode: opts.mode } }];
  }

  const { cmsTasks } = useCMSTasks();

  return usePortalQuery<IStatusResponse, unknown, IStatus>(url, {
    select: (data) => {
      const events = data.events.map((event) => {
        const slug = event.slug as keyof typeof eventMapping;
        return {
          slug: slug,
          title: eventMapping[slug],
          start_date: new Date(event.start_date),
          end_date: event.end_date ? new Date(event.end_date) : undefined,
          name: event.name,
        };
      });

      const notifications = [
        ...data.status.map((stat) => ({
          date: new Date(
            stat.type === "message" ? stat.updated_date : stat.lastChanged || ""
          ),
          dateString: dateFormatter(
            dayjs(
              new Date(
                stat.type === "message"
                  ? stat.updated_date
                  : stat.lastChanged || ""
              )
            ),
            { ignoreTime: true, withWeekday: true }
          ),
          status: stat,
        })),
        ...events.map((event) => ({
          date: new Date(event.start_date),
          dateString: dateFormatter(dayjs(new Date(event.start_date)), {
            ignoreTime: true,
            withWeekday: true,
          }),
          event,
        })),
      ].sort((not1, not2) => compareDesc(not1.date, not2.date));

      const days = notifications
        .map((notif) => notif.dateString)
        .filter((day, index, array) => array.indexOf(day) === index);

      const daysWithNotifications = days.map((day) => ({
        day,
        notifications: notifications.filter((notif) => {
          return notif.dateString === day;
        }),
      }));

      return {
        notifications,
        daysWithNotifications,
        status: data.status,
        events,
        badges: {
          tasks: cmsTasks?.filter((t) => t.status && !t.completed).length,
          messages:
            data.status.filter((s) => s.type === "message").length || undefined,
          documents:
            data.status.filter((s) => s.type === "document").length ||
            undefined,
        },
      };
    },
    ...options,
  });
}

export { useStatus };
