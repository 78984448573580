import { useParams } from "react-router-dom";
import { usePortalQuery } from "../../../../../components/usePortalQuery";
import { useProfile } from "../../../../../components/useProfile";
import {
  favorites,
  ICMSMessageTemplateWithRights,
  ownTemplates,
} from "./types";

/** This should be used by useTemplates hook only. */
export const useCMSTemplates = () => {
  const params = useParams();
  const { data: user } = useProfile("agent");

  const isFavorites = params.categoryId === favorites.displaySlug;
  const isOwnTemplates = params.categoryId === ownTemplates.displaySlug;

  const cmsTemplatesQuery = usePortalQuery<ICMSMessageTemplateResponse>(
    "cms/message_templates",
    {
      staleTime: 60 * 1000,
      enabled: user?.type === "agent",
    }
  );

  const cmsFavoritesQuery =
    usePortalQuery<ICMSMessageTemplateFavoritesResponse>(
      "agents/message_templates/cms_favorites",
      {
        staleTime: 60 * 1000,
        enabled: user?.type === "agent",
      }
    );

  const cmsFavorites =
    cmsTemplatesQuery.data?.data?.filter((cmsTemplate) => {
      return cmsFavoritesQuery.data?.favorites.some(
        (favorite) => favorite.cms_uid === cmsTemplate.uid
      );
    }) || [];

  const cmsCategoriesQuery = usePortalQuery<ITemplateCategoriesResponse>(
    "cms/message_template_categories",
    {
      staleTime: 60 * 1000,
      enabled: user?.type === "agent",
    }
  );

  const cmsTemplates = isOwnTemplates
    ? []
    : (isFavorites
        ? cmsFavorites
        : cmsTemplatesQuery.data?.data?.filter(
            (template) => template.category === params.categoryId
          )) || [];

  const cmsTemplatesWithRights: ICMSMessageTemplateWithRights[] =
    cmsTemplates.map((template) => {
      const isFavorite = !!cmsFavoritesQuery.data?.favorites.some(
        (favorite) => favorite.cms_uid === template.uid
      );

      return {
        ...template,
        can_remove_favorite: isFavorite,
        can_edit: false,
        can_add_favorite: !isFavorite,
      };
    }) || [];

  return {
    cmsCategoriesQuery,
    cmsTemplatesQuery,
    cmsFavoritesQuery,
    isLoading:
      cmsCategoriesQuery.isLoading ||
      cmsTemplatesQuery.isLoading ||
      cmsFavoritesQuery.isLoading,
    categories: cmsCategoriesQuery.data?.data || [],
    cmsTemplates: cmsTemplatesWithRights,
    cmsFavorites,
  };
};
