import { ReactNode, Suspense } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";

import { MediaBrowser, Loading } from "therese";

import { usePortalParams } from "./components/usePortalParams";
import { useStatus } from "./components/useStatus";

import BaseLayout from "./Layout";
import { spousePaths, userPaths } from "./paths";

import { ReactComponent as Icon } from "symbols/document.svg";
import { AccessModeEnum } from "./utils/enums";

function Layout() {
  const params = useParams();
  const sale_id = parseInt(params.sale_id as string, 10);
  const { mode } = usePortalParams();
  const { data: status } = useStatus({ sale_id, mode });

  return (
    <BaseLayout
      rootPath={`/${sale_id}`}
      paths={mode === AccessModeEnum.SPOUSE ? spousePaths : userPaths}
      badges={status?.badges}
    />
  );
}

interface IMediaLayoutProps {
  title: string;
  children: ReactNode;
}

function MediaLayout(props: IMediaLayoutProps) {
  return (
    <>
      <Helmet title={props.title}>
        <body className="view-full" />
      </Helmet>
      <Suspense
        fallback={
          <MediaBrowser title={props.title} icon={<Icon />} sidebar={false}>
            <Loading skipDelay />
          </MediaBrowser>
        }
      >
        {props.children}
      </Suspense>
    </>
  );
}

export { Layout, MediaLayout };
