import cx from "classnames";
import { NavLinkProps, NavLink } from "react-router-dom";

interface IInternalNavLinkProps extends Omit<NavLinkProps, "className"> {
  className?: string;
  activeClassName?: string;
}

function InternalNavLink({ activeClassName, ...props }: IInternalNavLinkProps) {
  return (
    <NavLink
      {...props}
      className={({ isActive }) =>
        cx(props.className, {
          [activeClassName || ""]: isActive,
        })
      }
    />
  );
}

export { InternalNavLink as NavLink };
