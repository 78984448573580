import { bool, string, setLocale } from "yup";

setLocale({
  mixed: {
    required: '"${label}" må oppgis for å gå videre',
  },
  string: {
    email: '"${label}" må være en gyldig e-postaddresse',
  },
});

const numberRegex = /^[0-9]{8}$/;

function phone() {
  return string().matches(numberRegex, '"${label}" skal bestå av 8 tall');
}
function boolean() {
  return bool().nullable();
}

export { phone, boolean };
export { object, string, number, date, array, mixed } from "yup";
