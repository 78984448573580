import { lazy } from "react";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { Header } from "therese";

import { Suspense } from "../../components/Suspense";
import { Container } from "../../components/Layout/Layout";

const AddLead = lazy(() => import("./AddLead"));

const title = "Legg til interessent";
function AddLeadView() {
  const { sale_id } = useParams();
  return (
    <>
      <Helmet title={title} />
      <Header
        title={title}
        linkAs={Link}
        close={{ title: "Lukk", to: `/${sale_id}` }}
      />
      <Container>
        <Suspense>
          <AddLead />
        </Suspense>
      </Container>
    </>
  );
}

export default AddLeadView;
