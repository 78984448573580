import React, { lazy, useRef, useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import {
  Loading,
  MenuItem,
  MenuItemActiveClassName,
  EmptyState,
  UIText,
  Badge,
} from "therese";

import { NavLink } from "../../components/NavLink";
import { Container } from "../../components/Layout/Layout";
import { SplitView } from "../../components/SplitView";
import { useWebSocket } from "../../components/useWebSocket";
import { _experimental_PushNotifications as PushNotifications } from "../../components/PushNotifications";

import { useConversations, useMarkConversationAsUnread } from "./hooks";
import { contextPathMap } from "./utils";

import { ReactComponent as MessageIcon } from "symbols/20/message.svg";
import * as styles from "./styles.module.css";
import { Contact } from "./Conversation";

import Conversation from "./Conversation";

interface IMessagesProps {
  title?: string;
  nested?: boolean;
  showMarkUnread?: boolean;
  hideView?: boolean;
}

const Messages: React.FC<IMessagesProps> = (props) => {
  useWebSocket();

  return props.hideView ? (
    <MessagesUnavailable />
  ) : (
    <Routes>
      <Route path="/" element={<Layout {...props} />}>
        <Route path=":conversation_id/*" element={<Conversation />} />
        <Route path="" element={<SelectConversation />} />
        <Route path="*" element={<SelectConversation />} />
      </Route>
    </Routes>
  );
};

type ILayoutProps = IMessagesProps;

const Layout: React.FC<ILayoutProps> = (props) => {
  const { data: conversations, queryKey } = useConversations();
  const { mutateAsync } = useMarkConversationAsUnread(queryKey);

  const markUnread = props.showMarkUnread
    ? (conversation_id: number) => mutateAsync({ conversation_id })
    : undefined;

  return (
    <SplitView
      mapKeys={["sale_id", "conversation_id"]}
      title={props.title || "Meldinger"}
      nested={props.nested}
    >
      {conversations === undefined ? (
        <Loading skipDelay />
      ) : (
        conversations.map(({ contact, unread, id, can_unread, ...rest }) => {
          const context = rest.context;
          return context ? (
            <MenuItem
              as={NavLink}
              to={`./${contextPathMap[CONTEXT][context]}`}
              key={context}
              activeClassName={MenuItemActiveClassName}
              className={styles.menuItem}
              badge={
                <div className={styles.badges}>
                  {can_unread && markUnread ? (
                    <MarkUnread onClick={() => markUnread?.(id)} />
                  ) : null}
                  {unread ? (
                    <Badge theme="circle" aria-hidden>
                      {unread}
                    </Badge>
                  ) : null}
                </div>
              }
              end
            >
              <Contact context={context} contact={contact} />
            </MenuItem>
          ) : null;
        })
      )}

      <PushNotifications />
    </SplitView>
  );
};

const SelectConversation: React.FC = () => {
  return (
    <Container>
      <EmptyState>Velg samtale fra menyen..</EmptyState>
    </Container>
  );
};

const MessagesUnavailable: React.FC = () => {
  return (
    <Container>
      <EmptyState>
        Chat er ikke tilgengelig i kundeportalen for meglere.
      </EmptyState>
    </Container>
  );
};

interface IMarkUnreadProps {
  onClick: () => void;
}

const MarkUnread = (props: IMarkUnreadProps) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  useEffect(() => {
    return () => setTooltipVisible(false);
  }, []);

  return (
    <UIText
      ref={buttonRef}
      className={styles.markUnread}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        props.onClick();
      }}
      as="button"
      onMouseEnter={() => setTooltipVisible(true)}
      onMouseLeave={() => setTooltipVisible(false)}
    >
      <MarkUnreadTooltip<HTMLButtonElement>
        parentRef={buttonRef}
        visible={tooltipVisible}
      />
      <MessageIcon />
    </UIText>
  );
};

interface IMarkUnreadTooltipProps<Element extends HTMLElement> {
  parentRef: React.MutableRefObject<Element | null>;
  visible: boolean;
}

const MarkUnreadTooltip: React.FC<IMarkUnreadTooltipProps<HTMLElement>> = (
  props
) => {
  const position = props.parentRef.current?.getBoundingClientRect();

  return (
    <UIText
      size="13"
      as="span"
      style={{
        top: `${(position?.top || 0) - 16}px`,
        left: `${(position?.left || 0) + (position?.width || 0) / 2}px`,
        display: props.visible ? "block" : "none",
      }}
      className={styles.markUnreadTooltip}
    >
      Marker som ulest
    </UIText>
  );
};

export default Messages;
