import { Outlet, useLocation, useNavigate } from "react-router-dom";

import {
  MenuMain,
  MenuGroup,
  MenuItem,
  MenuItemActiveClassName,
} from "therese";

import { Logo } from "./components/Logo";
import { NavLink } from "./components/NavLink";

import { ReactComponent as Info } from "symbols/circle_info.svg";

import { IPath } from "./paths";
import { usePortalQuery } from "./components/usePortalQuery";
import { useProfile } from "./components/useProfile";
import { usePortalContext } from "./PortalBoundary";
import { useEffect } from "react";
import { AccessModeEnum } from "./utils/enums";
import { IProperty } from "./api";

interface ILayoutProps {
  paths: IPath[];
  rootPath: string;
  badges?: Record<string, number | undefined>;
  pathsSuffix?: string | number;
}

declare global {
  interface Window {
    plausible?: Function;
  }
}

const useAddSlash = () => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!pathname.endsWith("/")) {
      navigate(pathname + "/" + search, { replace: true });
    }
  }, [pathname, navigate, search]);
};

function Layout(props: ILayoutProps) {
  const { data: user } = useProfile();

  const { data, isLoading } = usePortalQuery<IProperty>("v2/property", {
    enabled: user?.type !== "agent",
  });

  const { mode } = usePortalContext();

  const isProjectUnit =
    data?.project_relation === "ProjectUnit" && mode === AccessModeEnum.BUY;
  const isAgent = user?.type === "agent" && !mode;

  const isSpouse = data?.mode === AccessModeEnum.SPOUSE;

  useAddSlash();

  const location = useLocation();

  useEffect(() => {
    if (window.plausible) {
      let url = location.pathname;
      if (url.endsWith("/")) {
        // Replace every all digits with {id} "
        const redactedUrl = url.replaceAll(/\d+/g, "{id}");
        window.plausible("pageview", {
          u: redactedUrl + window.location.search,
        });
      }
    }
  }, [location]);

  return (
    <>
      <MenuMain logo={(lProps) => <Logo to={props.rootPath} {...lProps} />}>
        {props.paths.map((group, index) => {
          return (
            <MenuGroup title={group.title} key={`menugroup::${index}`}>
              {group.paths
                .filter((path) => {
                  const isVisible =
                    ((path.to !== "meldinger" && path.to !== "hjelp") ||
                      !isProjectUnit ||
                      isAgent) &&
                    !isLoading;

                  return isVisible;
                })
                .filter((path) => {
                  if (
                    (path.to === "meldinger" ||
                      path.to === "hjelp" ||
                      path.to === "fordeler") &&
                    isSpouse
                  ) {
                    return false;
                  } else {
                    return true;
                  }
                })
                .map(({ title, id, to, pathSuffix = true, ...item }) => {
                  let path = to;
                  if (pathSuffix && props.pathsSuffix) {
                    path = `${to}/${props.pathsSuffix}`;
                  }
                  return (
                    <MenuItem
                      key={`menuitem::${id}`}
                      as={NavLink}
                      activeClassName={MenuItemActiveClassName}
                      badge={(props.badges && props.badges[id]) || undefined}
                      to={path}
                      {...item}
                    >
                      {title}
                    </MenuItem>
                  );
                })}
              {group.id === "user_navigation" && (
                <MenuItem as="div" icon={Info}>
                  {`Versjon ${VERSION}`}
                </MenuItem>
              )}
            </MenuGroup>
          );
        })}
      </MenuMain>

      <main id="hovedinnhold" className="main" tabIndex={-1}>
        {/*
          For some reason safari needs this extra wrapping div to
          have sticky elements behave properly...
          https://stackoverflow.com/a/57938266
         */}
        <div className="main-inner">
          <Outlet />
        </div>
      </main>
    </>
  );
}

export default Layout;
