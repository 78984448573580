import { Badge } from "therese";

import { usePhase } from "./usePhases";
import { PhasesArray } from "../utils/phaseText";
import { useIsValuation } from "./useIsValuation";

type BadgeType =
  | "muted"
  | "success-light"
  | "warning"
  | "success"
  | "circle"
  | "outline"
  | "default"
  | "error";

const badge_theme = ["muted", "success-light", "warning", "success"];

interface IPhaseBadgeProps {
  phase: Phase;
  className?: string;
  ignoreValuation?: boolean;
}

function PhaseBadge(props: IPhaseBadgeProps) {
  const isValuation = useIsValuation();

  const { data } = usePhase(
    isValuation && !props.ignoreValuation
      ? "valuation"
      : PhasesArray[props.phase]
  );

  if (!data) {
    return null;
  }

  return (
    <Badge
      className={props.className}
      theme={
        (isValuation && !props.ignoreValuation
          ? "success-light"
          : badge_theme[props.phase]) as BadgeType // Weird type definitions...
      }
    >
      {data.badge}
    </Badge>
  );
}

export { PhaseBadge };
