import { useParams } from "react-router-dom";

import { usePortalContext } from "../PortalBoundary";

function usePortalParams(): IPortalParams {
  const { sale_id } = useParams();
  const { mode } = usePortalContext();
  const params: IPortalParams = {};
  if (mode) {
    params.mode = mode;
  }
  if (sale_id) {
    params.sale_id = parseInt(sale_id, 10);
  }
  return params;
}

export { usePortalParams };
