// extracted by mini-css-extract-plugin
var _1 = "cZR5Yqrv4dV0GUoMzRx1";
var _2 = "Q9dWgGff_sXGaFXRxVCK";
var _3 = "xrMg3Zb8LTN6McNHk5A8";
var _4 = "Q2zWV7e4wst7J0qiawzJ";
var _5 = "60.5em";
var _6 = "EuWJX418ipPSBq602ZNb";
var _7 = "U8DUvDyCgy7WxHt_WXaC";
var _8 = "YZUQCjjQ9IzJxuT33bbX";
var _9 = "hWO2whOTcV_4oY0s3fsr";
export { _1 as "button", _2 as "form", _3 as "formContainer", _4 as "input", _5 as "medium", _6 as "messageTemplatesButton", _7 as "messageTemplatesButtonContainer", _8 as "paddingRight", _9 as "smallButton" }
