import { ReactElement } from "react";
import {
  Person,
  Dropdown,
  Card,
  CardSection,
  VStack,
  UIText,
  Avatar,
} from "therese";

import { userTypeMap } from "../utils";

interface IHeaderProps {
  contactPerson: ReactElement;
  users: IUser[];
}

function Header({ contactPerson, users }: IHeaderProps) {
  return (
    <>
      {contactPerson}

      {users && users.length ? (
        <Dropdown title="Samtale deltagere" hideTitle>
          <Card>
            <CardSection title="Deltagere">
              <ul>
                <VStack spacing="xs">
                  {users.map((user) => {
                    const name = user.preferred_name || user.full_name;
                    return (
                      <li key={`person::${user.id}`}>
                        <Person
                          title={name}
                          subTitle={userTypeMap[user.type]}
                          avatar={
                            <Avatar src={user.avatar} online={user.online} />
                          }
                        />
                      </li>
                    );
                  })}
                </VStack>
              </ul>
            </CardSection>
            <CardSection>
              <UIText size="13">
                Megler har tilgang til å lese alle meldinger og se
                samtaleloggen.
              </UIText>
            </CardSection>
          </Card>
        </Dropdown>
      ) : null}
    </>
  );
}

export default Header;
