/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { lazy } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";

import { Property, VStack, Badge } from "therese";

import { usePortalQuery } from "../../components/usePortalQuery";
import { Suspense } from "../../components/Suspense";
import { Container } from "../../components/Layout/Layout";
import { PhaseBadge } from "../../components/PhaseBadge";
import { address } from "../../utils/address";

import { ReactComponent as GridIcon } from "symbols/grid.svg";

import * as styles from "./styles.module.css";

const Status = lazy(() => import("./Status"));

function Overview() {
  const navigate = useNavigate();
  const { data } = usePortalQuery<IProperty>("v2/property");

  if (data === undefined) {
    return null;
  }

  return (
    <>
      <Helmet title="Min bolig" />
      <Container>
        <VStack>
          <Property
            onClick={() => {
              navigate("bolig/");
            }}
            className={styles.propertyCard}
            address={address.toDisplay(data.address)}
            place={address.toPlace(data.address)}
            price={data.phase === 0 ? "" : data.price}
            badge={data.phase ? <PhaseBadge phase={data.phase} /> : undefined}
            image={
              data.photo !== null ? (
                <>
                  <Badge
                    as={Link}
                    onClick={(e) => e.stopPropagation()}
                    to="bilder"
                    className={styles.photoBadge}
                    aria-label="Se alle bilder"
                  >
                    <GridIcon aria-hidden="true" /> {data.photo.amount}
                  </Badge>

                  <img
                    src={data.photo.preview}
                    alt=""
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate("bilder");
                    }}
                  />
                </>
              ) : undefined
            }
          />
          <Suspense>
            <Status />
          </Suspense>
        </VStack>
      </Container>
    </>
  );
}

export default Overview;
